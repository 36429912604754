
.Root{
  display: flex;
  flex-direction: column;
  align-items:center;
}

.Content{
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 300px;
  max-width: 1200px;
}

.Navbar{
  width: 300px;
  height: 40px;
}

@font-face{
  font-family: Muli;
  src: url('./fonts/Muli-SemiBold.ttf');
}

.row-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.row-item {
  max-width: 400px;
  min-width: 250px;
}

.row-image {
  max-width: 600px;
  min-width: 250px;
}

.text-title{
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #16649E;
  margin-top: 10px;
  margin-bottom: 10px;
}

body{
  font-family: Muli;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #798DA3;
}

.info-box{
  margin-top: 50px;
  width: 250px;
  text-align: center;
}

.info-icon{
  display: flex;
  justify-content: center
}

.Footer{
  text-align: center;
  margin-top: 25px;
}